import axiosClient from './axiosClient';
import queryString from 'query-string';

export const trn_tasksApi = {
  getAll(params) {
    const url = '/trn_tasks';
    return axiosClient.get(url, { params });
  },
  
  getTaskAssign(data) {
    const param = queryString.stringify(data)
    const url = `/taskAssign?${param}`;
    return axiosClient.get(url, { });
  },
  
  getTaskConfirm(data) {
    const param = queryString.stringify(data)
    const url = `/taskConfirm?${param}`;
    return axiosClient.get(url, { });
  },

  getTaskDueDate(data) {
    const param = queryString.stringify(data)
    const url = `/taskDueDate?${param}`;
    return axiosClient.get(url, { });
  },

  updateTaskAssignRespond(params, data) {
    const url = `/taskAssign/respond`;
    return axiosClient.put(url, { params, data });
  },

  updateTaskConfirmRespond(params, data) {
    const url = `/taskConfirm/respond`;
    return axiosClient.put(url, { params, data });
  },

  updateTaskDueDateRespond(params, data) {
    const url = `/taskDueDate/respond`;
    return axiosClient.put(url, { trn_manage_id:params, data });
  },

  pending(params) {
    const url = `/process/pending`;
    return axiosClient.put(url, { trn_manage_id:params });
  },
  updateChangePIC(params) {
    const url = `/trntasksChange`;
    return axiosClient.put(url, params);
  },

};
