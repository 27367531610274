import { Button, Col, Modal, Row, Tabs,Input, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import TableI3links from '../../../../commons/commonComponents/tableI3links/TableI3links';
import { toast } from 'react-toastify';
import { teamState } from '../../../../redux/selector';
import { useSelector } from 'react-redux';
import { hrmsApi } from '../../../../api/hrmApi';
import { FormatDate } from '../../../../commons/commonFunctions/FormatDate';
import { FaPlus, FaRegTimesCircle,FaFilter,FaEraser } from 'react-icons/fa';

FilterUser_v2.propTypes = {};
const items = [
  {
    label: <h2 style={{ fontWeight: 'bold',marginBottom:0 }}>ユーザー</h2>,
    key: `1`,
    children: (
      <>
        <Button style={{ width: '100%', marginTop: 10, textAlign: 'left', paddingLeft: 20, backgroundColor: `#b0b0b0`, color: `#fff`,borderRadius:'2px' }} className={'common__process__control--btn'} onClick={() => {}}>
          メンバー一覧
        </Button>
      </>
    ),
  }
];
function FilterUser_v2(props) {
  const { isOpen, handleOkSubmit, handleCancelSubmit,data } = props;
  const teamInfo = useSelector(teamState);
  const [open, setOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [userActive, setUserActive] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const handleOk = (e) => {
    const value = {...userActive}
    if(Object.keys(value).length === 0){
      toast.warning('ユーザーを選択してください。', {
        toastId: 'toast_warning1',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        theme: 'light',
        pauseOnHover: false,
        hideProgressBar: true,
      });
      return
    }
    handleOkSubmit(value);
    setOpen(false);
  };
  const handleCancel = (e) => {
    handleCancelSubmit();
    setOpen(false);
  };
  const handleActiveUser = (value)=>{
    // console.log(value);
    setUserActive(value)
  }

/////////////////////////////////////////
const [listUserFilter, setlistUserFilter] = useState([]);
const [sortFilter, setSortFilter] = useState({
  filterUserName: '',
});
const handleSearchText = (selectedKeys, confirm, dataIndex, filterName) => {
  confirm();
  setSortFilter((prevValue) => {
    const newListSort = { ...prevValue };
    newListSort[filterName] = selectedKeys[0];
    return { ...newListSort };
  });
};

const handleReset = (clearFilters,filterName,confirm) => {
  if(filterName === 'filterStartTime' || filterName === 'filterEndTime' || filterName === 'filterStartTimeReality'|| filterName === 'filterEndTimeReality'){
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = [];
      return { ...newListSort };
    });
  }else{
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = '';
      return { ...newListSort };
    });
  }
  confirm()
  clearFilters();
};

///// lọc theo text
const getColumnSearchProps = (dataIndex, placeholder, filterName) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        placeholder={placeholder}
        value={`${selectedKeys[0] || ''}`}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
          icon={<FaFilter />}
          size='small'
          style={{
            width: 90,
          }}
        ></Button>
        <Button
          onClick={() => {
            setSelectedKeys([])
            return clearFilters && handleReset(clearFilters,filterName,confirm)
          }}
          size='small'
          type='primary'
          style={{
            width: 90,
          }}
          icon={<FaEraser />}
        ></Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FaFilter
      style={{
        color: filtered ? '#000' : undefined,
      }}
    />
  ),
});
  const [columnsUser, setColumnsUser] = useState(() => {
    return [
      {
        title: 'ユーザ名',
        dataIndex: 'full_name',
        key: '0',
        sorter: {
          compare: (a, b) => a.full_name?.localeCompare(b.full_name),
          multiple: 1,
        },
        ...getColumnSearchProps('full_name', 'タスク名', 'filterUserName'),
      },
    ];
  });


  ///sort khi có thông tin sort thay đổi
  useEffect(() => {
    let dataFilterUsers = [...dataSource];
    if (sortFilter.filterUserName?.trim()) {
      dataFilterUsers = dataFilterUsers.filter((item) => item.full_name.includes(sortFilter.filterUserName?.trim()));
    }
    setlistUserFilter(() => {
      return dataFilterUsers.map((item, index) => {
        return { ...item, no: index + 1 };
      });
    });
  }, [sortFilter]);

  useEffect(() => {
    if (isOpen) {
      const getAllUser = async () => {
        try {
          setSpinning(true)
          const result = await hrmsApi.usersApi.getAllUser({
            listSearch:{},
            team_id: teamInfo.listTeam[0]?.team_id,
            page: Number.parseInt(null) || 1,
            limit: Number.parseInt(null) || 10,
          });
          if (result) {
            const { data, pagination = {} } = result;
            const dataListUser =
              data?.length > 0
                ? data.map((item, index) => {
                    item.key = index;
                    item.entry_date = FormatDate(new Date(item.entry_date));
                    item.edit_date = FormatDate(new Date(item.edit_date));
                    return item;
                  })
                : [];
            setDataSource(dataListUser);
            setlistUserFilter(dataListUser)
            setUserActive(null);
            setSpinning(false)
            return;
          }
        } catch (error) {
          if (error.message && !toast.isActive({ toastId: 'toast_warning' })) {
            toast.warning(error.message, {
              toastId: 'toast_warning',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
        }
      };
      getAllUser();
      setOpen((prev) => {
        return !prev;
      });
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        title={<h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>担当者変更</h2>}
        footer={
          <Row justify={'space-between'}>
            <Button
              className='bg__red common__process__control--btn'
              onClick={() => {
                handleCancel();
              }}
            >
              キャンセル
            </Button>
            <Button
              className={'bg__blue common__process__control--btn'}
              onClick={() => {
                handleOk()
              }}
            >
              OK
            </Button>
          </Row>
        }
        width={900}
        open={open}
        closable={false}
      >
        <div>
          <h2 style={{ marginBottom: 20 }} className='color__text__black'>
            担当者を設定してください。
          </h2>

          <div>
            <Row gutter={[16, 16]} justify={'space-between'} align={'stretch'}>
              <Col span={10}>
              <div className='box_shadow' >
                <Tabs type='card' items={items} style={{height:'100%'}} />

              </div>
              </Col>
              <Col span={14}>
              <div className='box_shadow' >
                <TableI3links
                  className='table__user__actor common__table'
                  Pagination={{}}
                  // idProcess={userActive?.user_id ? true : null}
                  handlePaginationNexted={() => {
                    // handlePaginationNexted();
                  }}
                  handleClick={(value) => {
                    handleActiveUser(value)  
                  }}
                  type='radio'
                  titleLocal={''}
                  columns={columnsUser}
                  dataSource={listUserFilter}
                  total={listUserFilter.length || 10}
                  spinning={spinning}
                  position='bottomRight'
                  showSizeChanger={true}
                  defaultPageSize={10}
                  pageSizeOptions={['10', '15', '20']}
                  scroll={{ y: 250 }}
                />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FilterUser_v2;
